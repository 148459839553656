<template>
  <div class="gt_control gt_control-select">
    <label class="gt_sub-title" v-if="title && title.length > 0">{{title}}</label>
    <VSelect :items="options" @input.prevent="change" @focus.prevent="focus" @blur.prevent="blur" v-model="val" :disabled="readonly || (options && options.length <= 1)">
    </VSelect>
  </div>
</template>

<script>
export default {
  name: "Select",
  data() {
    return {
      val: ""
    };
  },
  props: {
    id: [String, Number],
    type: String,
    title: String,
    value: [String, Number],
    options: {
      type: Array,
      required: false,
      default() {
        return [
          {
            text: "Unset",
            value: "unset"
          },
          {
            text: "None",
            value: "none"
          },
          {
            text: "Initial",
            value: "initial"
          },
          {
            text: "Inherit",
            value: "inherit"
          },
          {
            text: "Underline",
            value: "underline"
          },
          {
            text: "Solid",
            value: "solid"
          },
          {
            text: "Dotted",
            value: "dotted"
          },
          {
            text: "Dashed",
            value: "dashed"
          },
          {
            text: "Double",
            value: "double"
          },
          {
            text: "Overline",
            value: "overline"
          },
          {
            text: "Line Through",
            value: "line-through"
          },
          {
            text: "Wavy",
            value: "wavy"
          },
          {
            text: "Blink",
            value: "blink"
          }
        ];
      }
    },
    readonly: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  created() {
    this.$emit("created", this);
    this.val = this.value;
  },
  methods: {
    setValue(value) {
      if (value != this.val) {
        this.val = value;
      }
    },
    onChange() {
      var value = event.target.value;
      this.$emit("onChange", this.id, this.val);
    },
    change() {
      this.$emit("change", this.id, this.val);
    },
    focus() {
      this.$emit("focus", this.id, this.val);
    },
    blur() {
      this.$emit("blur", this.id, this.val);
    }
  },
  watch: {
    value(newV, oldV) {
      if (oldV === newV)
        return;
      this.val = newV;
    }
  },
};
</script>

<style lang="scss" scoped>
$blue: #839bc0;
$text: #333333;
$textShade2: #7f7f7f;
$textShade3: #dadada;

.gt_control-select {
  label {
    display: block;
  }

  select {
    border: none;
    -webkit-box-shadow: 0px 0px 0px 1px #b5c4de;
            box-shadow: 0px 0px 0px 1px #b5c4de;
    border-radius: 3px;
    padding: 10px 10px;
    height: 37px;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    font-size: 15px;
    &:focus {
      -webkit-box-shadow: 0px 0px 0px 2px $blue;
              box-shadow: 0px 0px 0px 2px $blue;
    }
    &:disabled {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
    }
  }
}
</style>
