<template>
  <div class="gt_control gt_control-codeeditor">
    <AceEditor
      :fontSize="14"
      width="100%"
      :height="height"
      :className="name"
      :showPrintMargin="true"
      :showGutter="true"
      :highlightActiveLine="true"
      :mode="lang"
      theme="monokai"
      :onChange="readDataChange"
      :name="getName"
      :editorProps="{$blockScrolling: true}"
      :value="value"
      :readOnly="readonly"
      @sync="$emit('sync')"
    />
  </div>
</template>

<script>
import brace from "brace";
import { Ace as AceEditor } from "./brace";
import Select from "./Select";

import "brace/mode/ejs";
import "brace/mode/liquid";
import "brace/mode/json";
import "brace/mode/javascript";
import "brace/mode/html";
import "brace/mode/css";
import "brace/theme/monokai";

function unique() {
  const _p = "qwertyuiopasdfghjklzxcvbnm1234567890QWERTYUIOPASDFGHJKLZXCVBNM";
  const _l = _p.length;
  return "gf_xxxxxx".replace(/[xy]/g, function(c) {
    let r = (Math.random() * _l) | 0;
    let v = c == "x" ? _p[r] : c;
    return v;
  });
}
export default {
  name: "CodeEditor",
  components: {
    AceEditor,
    Select
  },
  props: {
    name: String,
    value: [String, Number, Boolean, Function, Object],
    lang: {
      type: String,
      default() {
        return "html";
      }
    },
    className: String,
    readonly: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "350px"
    }
  },
  computed: {
    getName() {
      return this.name + unique();
    }
  },
  data() {
    return {};
  },
  methods: {
    readDataChange(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="scss">
$black: #000000;
$white: #ffffff;
$grey: #dddddd;

$blue: #547aff;
$text: #333333;
$textShade2: #7f7f7f;
$textShade3: #dadada;

.gt_control-codeeditor {
  position: relative;
  // max-width: 30em;
  width: 100%;
  margin: 0;
  $radius: 5px;
  label.gt_sub-title {
    display: block;
    color: $text;
  }
}
.mr-15 {
  margin-right: 15px;
}
</style>